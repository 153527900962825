const CheckIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  opacity?: number
  color?: string
}> = ({ className = '', width = '14', height = '14', opacity = 1, color = 'white' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    color={color}
    opacity={opacity}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 1.5C11.25 1.71094 11.1562 1.89844 11.0156 2.03906L5.01562 8.03906C4.875 8.17969 4.6875 8.25 4.5 8.25C4.28906 8.25 4.10156 8.17969 3.96094 8.03906L0.960938 5.03906C0.820312 4.89844 0.75 4.71094 0.75 4.5C0.75 4.07812 1.07812 3.75 1.5 3.75C1.6875 3.75 1.875 3.84375 2.01562 3.98438L4.5 6.44531L9.96094 0.984375C10.1016 0.84375 10.2891 0.75 10.5 0.75C10.8984 0.75 11.25 1.07812 11.25 1.5Z"
      fill={color}
    />
  </svg>
)
export default CheckIcon
