import { formatDates } from 'utils/utils'

export type PackState = { statusText: string; ctaText: string; isButtonDisabled: boolean }

type PackCardHookResult = [packState: PackState, isDummyPack: boolean]

const usePackCardHook = (
  reservation_start_ts: number,
  drop_start_ts: number,
  is_sold_out: boolean,
  drop_end_ts: number,
  reservation_end_ts: number,
  sale_price: number
): PackCardHookResult => {
  const isDummyPack = sale_price === 0
  const currentTimeStamp = new Date().getTime()

  const packState: PackState = isDummyPack
    ? {
        statusText: 'COMING SOON',
        ctaText: 'COMING SOON',
        isButtonDisabled: true,
      }
    : is_sold_out || (reservation_end_ts < currentTimeStamp && drop_end_ts < currentTimeStamp) // reservation closed and drop closed
    ? {
        statusText: 'SOLD OUT',
        ctaText: 'SOLD OUT',
        isButtonDisabled: true,
      }
    : reservation_start_ts <= currentTimeStamp && drop_start_ts <= currentTimeStamp // reservation & drop started
    ? {
        statusText: 'PACK LAUNCHED',
        ctaText: 'BUY',
        isButtonDisabled: false,
      }
    : reservation_start_ts <= currentTimeStamp && drop_start_ts > currentTimeStamp // reservation started and drop not started
    ? {
        statusText: `LAUNCHES ON : ${formatDates(drop_start_ts, true, false, 'monthShortName')}`,
        ctaText: 'RESERVE',
        isButtonDisabled: false,
      }
    : reservation_end_ts > currentTimeStamp && drop_start_ts <= currentTimeStamp // reservation closed and drop started
    ? {
        statusText: `PACK LAUNCHED`,
        ctaText: 'BUY',
        isButtonDisabled: false,
      }
    : reservation_end_ts < currentTimeStamp && drop_start_ts > currentTimeStamp // reservation closed and drop not started
    ? {
        statusText: `DROPS: ${formatDates(drop_start_ts, true, false, 'monthShortName')}`,
        ctaText: 'BUY',
        isButtonDisabled: true,
      }
    : reservation_start_ts > currentTimeStamp && drop_start_ts === reservation_start_ts // reservation closed and dropTime === reservation_start_ts
    ? {
        statusText: `DROPS ON: ${formatDates(reservation_start_ts, true, false, 'monthShortName')}`,
        ctaText: 'COMING SOON',
        isButtonDisabled: true,
      }
    : reservation_start_ts > currentTimeStamp // upcoming pack
    ? {
        statusText: `RESERVE ON: ${formatDates(
          reservation_start_ts,
          true,
          false,
          'monthShortName'
        )}`,
        ctaText: 'COMING SOON',
        isButtonDisabled: true,
      }
    : {
        statusText: `RESERVATION BEGINS: ${formatDates(
          reservation_start_ts,
          true,
          false,
          'monthShortName'
        )}`,
        ctaText: 'EXPLORE',
        isButtonDisabled: false,
      }

  return [packState, isDummyPack]
}

export default usePackCardHook
