import { customStyled, Image, FlexBox, ThemeType, TextField } from '@rario/shared-components'
import { padding, PaddingProps, width, WidthProps } from 'styled-system'

export const PackDetailWrapper = customStyled.div<
  PaddingProps & WidthProps & { theme?: ThemeType }
>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "mainImage mainImage"
                        "details details";
  row-gap: 40px;
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
  ${padding};
  ${width};
  ol,ul {
    list-style: initial;
    padding-left: 20px;
    // list-style-position: inside;
  }

`

export const PackMainImage = customStyled(Image)`
  // grid-area: mainImage;
  // gap: 10px;
  // position: relative;
  filter: drop-shadow(3px 15px 15px rgba(0, 0, 0, 0.6));
`

export const PackDetails = customStyled(FlexBox)`
  grid-area: details;
  flex-direction: column;
  padding-top: 0px;
`

export const SeeAllTextWrapper = customStyled(TextField)<{ theme?: ThemeType }>`
@media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
  width: 90%;
  white-space: nowrap;
`

export const QuantityInputWrapper = customStyled(FlexBox)<{ theme?: ThemeType }>`
  min-width: min-content;
  padding: 0px;
  border-radius: 3.4px;
  & input{
    width: 35px;
    height: 25px;
    align-items: center;
    padding: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    caret-color: ${({ theme }) => theme.colors.secondary};
    text-align: center;
    margin: 0 5px;
    border-radius: 2px;
  }
  & input:hover,input:focus{
    background: rgba(255, 255, 255, 0.1);;
  }
  & div{
    justify-content: center;
    border-radius: 2px;
  }
 & input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
 & input[type=number] {
    -moz-appearance: textfield;
  }
& .arrow{
  cursor: pointer;
  user-select: none;
}
-webkit-tap-highlight-color: transparent;
`
export const ReservedIconContainer = customStyled(FlexBox)<{ theme?: ThemeType }>`
@media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
  & svg{
    width: 22px;
    height: 16px;
  }
}
`
export const Overlay = customStyled(FlexBox)<{ theme?: ThemeType }>`
  width: 120%;
  height: 130%;
  background: ${({ theme }) => theme.colors.blacks[8]};
  z-index: 9999;
  position: absolute;
  filter: blur(20px);
`

export const PackLabelContainer = customStyled(FlexBox)<{ theme?: ThemeType }>`
  width: 307px;
  border-radius: 2px;
  backdrop-filter: blur(2px);
  background: ${({ theme }) => theme.gradients.labelContainerBackGround};
`

export const PackPurchaseButtonTextWrapper = customStyled(TextField)<{
  isBottomButtonRequired?: boolean
  theme?: ThemeType
  color?: string
}>`
    font-size: ${({ isBottomButtonRequired }) =>
      isBottomButtonRequired ? '14px' : '12px'} !important;
    font-weight: 600;
    text-transform: uppercase;
    color: ${({ theme, color = theme.colors.black }) => color};
    line-height: 14px !important;
    padding-bottom: 0px !important;
`
