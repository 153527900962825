import { FlexBox, theme, Button, trackEvent, TextField } from '@rario/shared-components'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { CatalogTypeEnum, PacksDoc, PaymentKycStateEnum } from 'interfaces'
import { PackState } from 'hooks/usePackCardHook'
import {
  getUserReservationPurchaseStatus,
  createUserReservation,
  createUserOrder,
} from 'api/Order.api'
import { emitCustomEvent } from 'components/CustomEvents'
import { IApiException } from 'api/Api.exception'
import { useRouter } from 'next/router'
// import CheckIcon from 'assets/svg/CheckIcon'
import { /* getFinalSellingPrice, */ isUsernameValid } from 'utils/utils'
import { /* PackPurchaseButtonTextWrapper, */ ReservedIconContainer } from './PackDetail.styles'

import usePageControl from 'hooks/usePageControl'
import { AppConfigContext } from 'contexts/AppConfigContext'
import { useCustomUser } from 'contexts/CustomUserProvider'
import { ModalContext } from 'components/ModalManager/ModalProvider'
import { useCookies } from 'react-cookie'
import { UTM_COOKIES } from 'constant'
import BottomButtonWrapper from 'components/BottomButtonWrapper/BottomButtonWrapper'
// import DiscountPriceDescription from './DiscountPriceDescription'
import { useCustomAuth } from 'contexts/CustomAuthProvider'
// import useCurrencyConversion from 'hooks/useCurrencyConversion'
// import { UserWalletContext } from 'contexts/UserWalletContext'
import { trackApiErrorResponse } from 'utils/trackEventUtils'
import CheckIcon from 'assets/svg/CheckIcon'

type ButtonCtaType = {
  text: string
}
const BUTTON_TEXT_SOLD_OUT: ButtonCtaType = {
  text: 'Sold out',
}
const BUTTON_TEXT_NOT_ELIGIBLE: ButtonCtaType = {
  text: 'Not eligible',
}
const BUTTON_TEXT_RESERVATION_CLOSED: ButtonCtaType = {
  text: 'Closed',
}
const BUTTON_TEXT_RESERVE_PACK: ButtonCtaType = {
  text: 'RESERVE',
}
const BUTTON_TEXT_ALREADY_RESERVED: ButtonCtaType = {
  text: 'ALREADY RESERVED',
}
const BUTTON_TEXT_BUY_NOW: ButtonCtaType = {
  text: 'Buy Now',
}
const BUTTON_TEXT_PACK_RESERVED: ButtonCtaType = {
  text: 'PACK RESERVED',
}
const BUTTON_TEXT_LOGIN_AND_PROCEED: ButtonCtaType = {
  text: 'Login & Buy',
}

const INLINE_TEXT_RESERVATION_CLOSED =
  'You are a little late to the party. Reservation window for the pack closed.'
const INLINE_TEXT_BUY_NOW = 'You are not eligible for the pack purchase'

const PackPurchaseButton: React.FunctionComponent<{
  packsDoc: PacksDoc
  packState: PackState
  showQtyCb?: Function
  orderQty: number
  reservedCallback?: (val: boolean) => void
  setCTAInlineText?: (message: string) => void
  purchaseButtonCallBack?: (message: string, isPackReserved: boolean) => void
  nonLoggedInViewButtonCTA?: string
}> = ({
  packsDoc,
  packState,
  showQtyCb,
  orderQty,
  reservedCallback,
  setCTAInlineText,
  purchaseButtonCallBack,
  nonLoggedInViewButtonCTA,
}) => {
  const {
    userProfileModal,
    setUserProfileModal,
    setPaymentSummaryModal,
    setPhoneVerificationModal,
  } = useContext(ModalContext)
  const { accessToken } = useCustomAuth()
  const {
    objectID,
    name,
    sale_price,
    reservation_start_ts,
    // drop_start_ts,
    drop_end_ts,
    is_sold_out,
    catalog_type,
    // max_qty_allowed_per_user,
  } = packsDoc
  const isPackDetails = catalog_type === CatalogTypeEnum.PACK
  const appConfig = useContext(AppConfigContext)
  const { isIndianIP } = appConfig
  const { user } = useCustomUser()
  // const { walletBalance } = useContext(UserWalletContext)
  const { isHomeLandingPage, isPackPdp, isSachinLandingPage } = usePageControl()

  const RESERVED_SUCCESS_MESSAGE = `Good move. You can buy this pack when it launches.`
  const [cookies] = useCookies([
    UTM_COOKIES.UTM_SOURCE,
    UTM_COOKIES.UTM_CAMPAIGN,
    UTM_COOKIES.UTM_CONTENT,
    UTM_COOKIES.UTM_MEDIUM,
  ])
  const { ctaText, isButtonDisabled } = packState

  const buttonDefaultCtaText = useMemo(() => {
    if (is_sold_out) {
      return ctaText
    }
    if (!user && !isButtonDisabled) {
      if (nonLoggedInViewButtonCTA) {
        return nonLoggedInViewButtonCTA
      }
      return BUTTON_TEXT_LOGIN_AND_PROCEED.text
    }
    return ctaText
  }, [])

  const router = useRouter()
  const { asPath, query } = router
  const { referer } = query
  const currentTimeStamp = new Date().getTime()
  const [loading, setLoading] = useState(false)
  const [loadingSuccess, setLoadingSuccess] = useState(false)
  const [buttonCtaText, setButtonCtaText] = useState(buttonDefaultCtaText)
  const [disabled, setDisabled] = useState(isButtonDisabled)
  const [isAlreadyReserved, setAlreadyReserved] = useState(false)
  const [message, setMessage] = useState('')
  const isPurchaseAllowed = useRef(false)
  const reservationId = useRef(null)
  const [buyEnabled, setBuyEnabled] = useState(false)
  const userDetailsExists = useRef<boolean>(false)
  const userDetailsModalTriggered = useRef<boolean>(false)

  // const marketingWalletBalance = walletBalance?.marketingWalletBalance
  // const priceAfterDiscount = getFinalSellingPrice(
  //   orderQty > 0 ? sale_price * orderQty : sale_price,
  //   marketingWalletBalance!
  // )
  // const [formattedSalePrice] = useCurrencyConversion(
  //   orderQty > 0 ? sale_price * orderQty : sale_price
  // )
  // const [formattedPriceAfterDiscount] = useCurrencyConversion(priceAfterDiscount)

  useEffect(() => {
    if (
      !is_sold_out &&
      user &&
      currentTimeStamp >= reservation_start_ts &&
      currentTimeStamp <= drop_end_ts
    ) {
      fetchReservationState()
        .then(() => setLoading(false))
        .catch((e) => {
          setLoading(false)
          const error = e as IApiException
          const errorResponse = error.response ? error.response : { message: error.message }
          emitCustomEvent('errorCodes', errorResponse)
          trackApiErrorResponse(error, {
            subCategory: 'packs-fetch_pack_status_api_response_error',
            category: 'packs',
          })
          setDisabled(true)
        })
    }

    userDetailsExists.current = isUsernameValid(user?.profile?.firstName, user?.profile?.lastName)
  }, [user])

  useEffect(() => {
    if (userProfileModal?.isSuccess && userDetailsModalTriggered.current) {
      userDetailsExists.current = true
      userDetailsModalTriggered.current = false
      buy()
    }
  }, [userProfileModal])

  const fetchReservationState = async () => {
    setLoading(true)
    let data
    try {
      const resp = await getUserReservationPurchaseStatus(objectID.toString(), accessToken)
      data = resp.data

      const {
        stockAvailable,
        eligible,
        reserved,
        reservationAllowed,
        purchaseAllowed,
        reservationAvailable,
        eligibilityInfoText,
      } = data

      if (data.reservationId) {
        reservationId.current = data.reservationId
      }
      isPurchaseAllowed.current = purchaseAllowed

      // 1. Sold Out Case and Buy Disable - If Stock is not Available for that Pack.
      if (!stockAvailable) {
        setButtonCtaText(BUTTON_TEXT_SOLD_OUT.text)
        setDisabled(true)
      }
      // 2. Buy Now and Disable Buy Case - If the User is eligible for the Pack Purchase(If the user has exhausted the per user limit).
      else if (!eligible && eligibilityInfoText) {
        setButtonCtaText(BUTTON_TEXT_BUY_NOW.text)
        setDisabled(true)
        setCTAInlineText && setCTAInlineText(eligibilityInfoText)
        setMessage(eligibilityInfoText)
        // purchaseButtonCallBack && purchaseButtonCallBack(eligibilityInfoText, false)
      }
      // 3. Buy Now and Disable Buy Case - If the User is eligible for the Pack Purchase.
      else if (!eligible) {
        setButtonCtaText(BUTTON_TEXT_NOT_ELIGIBLE.text)
        setDisabled(true)
      }
      // 4. Buy Now CTA and Buy Enable Case - Reservation and Purchase Windows both overlapping and Both are open for the pack.
      else if (reservationAllowed && purchaseAllowed) {
        showQtyCb && showQtyCb(true)
        setButtonCtaText(BUTTON_TEXT_BUY_NOW.text)

        setBuyEnabled(true)
      }
      // 5. Closed CTA and Buy Disable Case - If the User does not reserved the pack and reservation has closed.
      else if (reservationAllowed && !purchaseAllowed && !reserved && !reservationAvailable) {
        setButtonCtaText(BUTTON_TEXT_RESERVATION_CLOSED.text)

        setDisabled(true)
      } // 6. Closed CTA and Buy Enable Case - If the User does not reserve the pack and the reservation and Purchase is closed for the Pack - Reservation is of past and Pack Drop is of Future and User has not Reserved the Pack.
      else if (!reserved && !reservationAllowed && !purchaseAllowed && !reservationAvailable) {
        setButtonCtaText(BUTTON_TEXT_RESERVATION_CLOSED.text)

        setDisabled(true)
      }
      // 7. Reserve Now CTA and Buy Disable Case - If the User does not reserve the pack and the reservation is Open - Reservation is of present and User has not Reserved the Pack.
      else if (!reserved && reservationAllowed) {
        setButtonCtaText(BUTTON_TEXT_RESERVE_PACK.text)
      }
      // 8. Already Reserve CTA with a Drops on Message and Buy Disable Case - If the User has already reserved the pack.
      else if (reserved && !purchaseAllowed) {
        setAlreadyReserved(true)
        reservedCallback && reservedCallback(true)
        setButtonCtaText(BUTTON_TEXT_ALREADY_RESERVED.text)

        setDisabled(true)
        setMessage(RESERVED_SUCCESS_MESSAGE)
        purchaseButtonCallBack && purchaseButtonCallBack(RESERVED_SUCCESS_MESSAGE, true)
      }
      // 9. Buy Now CTA and Buy Enable Case - If the User has already reserved the pack and Drop has started.
      else if (reserved && !reservationAllowed && purchaseAllowed) {
        showQtyCb && showQtyCb(true)
        setButtonCtaText(BUTTON_TEXT_BUY_NOW.text)

        setBuyEnabled(true)
        // setMessage('')
      }
      // 10. Buy Now CTA and Buy Disable Case - If the User does not reserved the pack and Drop has started and Buy Disabled.
      else if (!reserved && !reservationAllowed && reservationAvailable && !purchaseAllowed) {
        setCTAInlineText && setCTAInlineText(INLINE_TEXT_BUY_NOW)
        // purchaseButtonCallBack && purchaseButtonCallBack(INLINE_TEXT_BUY_NOW, false)
        setButtonCtaText(BUTTON_TEXT_BUY_NOW.text)

        setDisabled(true)
      }
      // 11. Closed CTA and Buy Disable Case.
      else {
        setCTAInlineText && setCTAInlineText(INLINE_TEXT_RESERVATION_CLOSED)
        // purchaseButtonCallBack && purchaseButtonCallBack(INLINE_TEXT_RESERVATION_CLOSED, false)
        setButtonCtaText(BUTTON_TEXT_RESERVATION_CLOSED.text)

        setDisabled(true)
      }
    } catch (e) {
      setLoading(false)
      const error = e as IApiException
      const errorResponse = error.response
        ? error.response
        : { message: error.message, status: error.statusCode }
      emitCustomEvent('errorCodes', errorResponse)
    }
  }

  const reserve = async () => {
    setLoading(true)

    try {
      const { data } = await createUserReservation({ catalogId: objectID }, accessToken)
      reservationId.current = data.id
      if (!isPurchaseAllowed.current) {
        setDisabled(true)
        setButtonCtaText(BUTTON_TEXT_PACK_RESERVED.text)
        setAlreadyReserved(true)
        reservedCallback && reservedCallback(true)
        setMessage(RESERVED_SUCCESS_MESSAGE)
        purchaseButtonCallBack && purchaseButtonCallBack(RESERVED_SUCCESS_MESSAGE, true)
      }
      trackEvent('Section Interacted', 'click', {
        sub_category: 'listings-packs',
        type: 'others',
        category: 'packs',
        pack_id: objectID,
        pack_name: name,
        click_action: 'pack_reserve_success',
      })
    } catch (e) {
      const error = e as IApiException
      const errorResponse = error.response ? error.response : { message: error.message }
      emitCustomEvent('errorCodes', errorResponse)
      trackApiErrorResponse(error, {
        subCategory: 'packs-create_reservation_cta_button',
        category: 'packs',
      })
    }
    setLoading(false)
  }

  const buy = async () => {
    if (!userDetailsExists.current) {
      userDetailsModalTriggered.current = true
      setUserProfileModal({ show: true })
      return
    }

    if (!reservationId.current) {
      return
    }

    setLoading(true)

    try {
      const { data } = await createUserOrder(
        {
          catalogId: objectID,
          reservationId: reservationId.current,
          quantity: orderQty,
          isIndianIP,
          utmSource: cookies?.[UTM_COOKIES.UTM_SOURCE]?.toString(),
          utmCampaign: cookies?.[UTM_COOKIES.UTM_CAMPAIGN]?.toString(),
          utmContent: cookies?.[UTM_COOKIES.UTM_CONTENT]?.toString(),
          utmMedium: cookies?.[UTM_COOKIES.UTM_MEDIUM]?.toString(),
        },
        accessToken
      )
      setLoadingSuccess(true)
      setPaymentSummaryModal({ orderId: data.orderId })
      setLoading(false)
      setLoadingSuccess(false)
    } catch (e) {
      const error = e as IApiException
      const errorResponse = error.response ? error.response : { message: error.message }
      emitCustomEvent('errorCodes', errorResponse)
      trackApiErrorResponse(error, {
        subCategory: 'packs-create_order_buy_button_cta',
        category: 'packs',
      })
    }
    setLoading(false)
  }

  const reserveAndBuy = async () => {
    if (reservationId.current && isPurchaseAllowed.current) {
      buy()
    } else if (!reservationId.current && isPurchaseAllowed.current) {
      reserve().then(() => buy())
    } else {
      reserve()
    }
  }

  const trackButtonClick = () => {
    const category = isHomeLandingPage ? 'homepage' : isPackDetails ? 'pdp' : 'plp'
    trackEvent('Section Interacted', 'click', {
      sub_category: category + '-buy_pack_cta',
      type: 'page',
      category,
      view_type: 'constrained',
      pack_id: objectID,
      pack_name: name,
      pack_price: sale_price,
      packPurchaseType: reservationId.current ? 'buy' : 'reserve',
      click_type: 'button',
      click_text: buttonCtaText,
    })
  }

  const onclickHandler = () => {
    trackButtonClick()
    if (!user) {
      setDisabled(true)
      referer
        ? router.push(`/login?redirect_uri=${asPath}`)
        : router.push(`/login?redirect_uri=${asPath}&referer=home`)
      return
    }
    if (!user?.isPhoneVerified && buyEnabled) {
      setPhoneVerificationModal({ canSkip: false })
      return
    }

    if (user?.kycStatus !== PaymentKycStateEnum.SUCCESS) {
      router.push('/kyc')
      return
    }

    reserveAndBuy()
  }

  // const buyCTAText = (isBottomButtonRequired: boolean) => {
  //   return (
  //     <PackPurchaseButtonTextWrapper isBottomButtonRequired={isBottomButtonRequired}>
  //       {buttonCtaText.toUpperCase()} for
  //       <PackPurchaseButtonTextWrapper
  //         isBottomButtonRequired={isBottomButtonRequired}
  //         color={theme.colors.darkGrey}
  //         px={'3px'}
  //         textDecoration={'line-through'}
  //       >
  //         {formattedSalePrice}
  //       </PackPurchaseButtonTextWrapper>
  //       {formattedPriceAfterDiscount}
  //     </PackPurchaseButtonTextWrapper>
  //   )
  // }

  const isBottomButtonRequired = isPackPdp || isSachinLandingPage
  return (
    <>
      <FlexBox flexDirection={'column'} justifyContent="center" flexGrow={1}>
        <FlexBox
          width={'100%'}
          flexDirection="row"
          alignSelf="flex-end"
          position="relative"
          alignItems="center"
        >
          {isAlreadyReserved ? (
            <BottomButtonWrapper isStyleActive={isBottomButtonRequired}>
              <FlexBox flexDirection={'column'} position={'relative'}>
                {buttonCtaText && (
                  <FlexBox alignItems="center" justifyContent={'center'}>
                    <ReservedIconContainer pt="3px" alignItems="center" pl={'5px'}>
                      <CheckIcon height="30px" width="25px" color={theme.colors.secondary} />
                    </ReservedIconContainer>
                    <TextField
                      fontFamily={theme.fonts.primary}
                      letterSpacing={theme.letterSpacings.primary}
                      fontWeight={600}
                      fontSize={'14px'}
                      lineHeight={'18px'}
                      textTransform={'uppercase'}
                      color={theme.colors.secondary}
                      pb={0}
                      pl={'10px'}
                    >
                      {buttonCtaText}
                    </TextField>
                  </FlexBox>
                )}
                {message && isSachinLandingPage && (
                  <FlexBox>
                    <TextField
                      fontSize={'12px'}
                      lineHeight={'14px'}
                      fontWeight={400}
                      textAlign="center"
                    >
                      {message}
                    </TextField>
                  </FlexBox>
                )}
              </FlexBox>
            </BottomButtonWrapper>
          ) : (
            <BottomButtonWrapper isStyleActive={isBottomButtonRequired}>
              <FlexBox flexDirection={'column'} width="100%">
                {/* {isPackPdp && marketingWalletBalance && marketingWalletBalance > 0 ? (
                  <FlexBox pb="16px">
                    <DiscountPriceDescription
                      salePrice={sale_price}
                      marketingWalletBalance={marketingWalletBalance}
                    />
                  </FlexBox>
                ) : (
                  <></>
                )} */}
                <Button
                  variant={'dynamic'}
                  colorVariant={'whiteFilled'}
                  fontSize={isBottomButtonRequired ? '14px' : '12px'}
                  disabled={disabled}
                  onClick={onclickHandler}
                  loading={loading}
                  success={loadingSuccess}
                  width={'100%'}
                >
                  {/* {buttonCtaText === BUTTON_TEXT_BUY_NOW.text &&
                  marketingWalletBalance &&
                  marketingWalletBalance > 0
                    ? buyCTAText(isBottomButtonRequired)
                    : buttonCtaText.toUpperCase()} */}
                  {buttonCtaText.toUpperCase()}
                </Button>
                {message && isSachinLandingPage && (
                  <FlexBox>
                    <TextField
                      pt={'5px'}
                      fontSize={'12px'}
                      lineHeight={'14px'}
                      fontWeight={400}
                      textAlign={'center'}
                    >
                      {message}
                    </TextField>
                  </FlexBox>
                )}
              </FlexBox>
            </BottomButtonWrapper>
          )}
        </FlexBox>
      </FlexBox>
    </>
  )
}

export default PackPurchaseButton
